export default {
    data() {
        return {
            i_tree: require('@/assets/platform_data/icon-tree@2x.png'),
            i_shrub: require('@/assets/platform_data/icon-shrub@2x.png'),
            i_lawn: require('@/assets/platform_data/icon-lawn@2x.png'),
            i_potting: require('@/assets/platform_data/icon-potting@2x.png'),
            tree: require('@/assets/home/IMG-tree.png'),
            i_suppliers: require('@/assets/platform_data/icon-suppliers@2x.png'),
            i_buyer: require('@/assets/platform_data/icon-buyer@2x.png'),
            i_driver: require('@/assets/platform_data/icon-driver@2x.png'),
            i_order: require('@/assets/platform_data/icon-order@2x.png'),
            middle_title_bg: require('@/assets/platform_data/middle_title_bg.png'),
            icon_location: require('@/assets/platform_data/icon_location@2x.png'),
            i_triangle: require('@/assets/platform_data/i-triangle.png'),
            // 云展会相关
            bg_live: 'https://img.miaocang.cc/pcImg/cloud/background_exhibition_2023@2x.png',
            bg_exhibition: 'https://img.miaocang.cc/pcImg/cloud/background_live@2x.png',
            i_exhibition: require('@/assets/cloud/icon_exhibition_2023@2x.png'),
            i_live: require('@/assets/cloud/icon_live_2023@2x.png'),
            i_service: require('@/assets/cloud/icon_service_2023@2x.png'),
            i_transfer: require('@/assets/cloud/icon_transfer_2023@2x.png'),
            i_news: require('@/assets/cloud/icon_news_2023@2x.png'),
            i_review: 'https://img.miaocang.cc/e2pd8sofe956czm2fuh6.png',
            // btn_shop: 'https://img.miaocang.cc/pcImg/cloud/button_shop_2023@2x.png',
            btn_shop: 'https://img.miaocang.cc/mpImg/button_shop_2023_2x.png',
            // btn_app: 'https://img.miaocang.cc/pcImg/cloud/button_app_2023@2x.png',
            btn_app: 'https://img.miaocang.cc/mpImg/button_app_2023_2x.png',
            btn_shopping_guide: 'https://img.miaocang.cc/mpImg/button_shopping_guide_2023_2x.png',
            btn_exhibitor: 'https://img.miaocang.cc/mpImg/button_exhibitor_2023_2x.png',
            btn_app: 'https://img.miaocang.cc/mpImg/button_app_2023_2x.png',
            btn_review_exhibition: 'https://img.miaocang.cc/mpImg/review/review_exhibition@2x.png',
            btn_review_live: 'https://img.miaocang.cc/mpImg/review/review_live@2x.png',
            qrcode_shop: 'https://img.miaocang.cc/pcImg/cloud/mp_shop.png',
            qrcode_app: 'https://img.miaocang.cc/pcImg/cloud/mp_app.png',
            qrcode_exhibitor: 'https://img.miaocang.cc/4aut20ned7qsqb7vr3qc.jpg',
            i_vr: require('@/assets/cloud/icon_vr@2x.png'),
            i_photo: require('@/assets/cloud/icon_photo@2x.png'),
            flower_tour_map: 'https://img.miaocang.cc/pcImg/cloud/flower-tour/map.png',
            shopping_guide_map: 'https://img.miaocang.cc/pcImg/cloud/shopping-guide/index/map.png'
        }
    }
}